import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { GenericCard } from "./GenericCard";

export const DataCard = (props: {
  title: string;
  dataValue: string;
  timeframe?: string;
  color?: string;
}) => {
  const { title, dataValue, timeframe = "", color = "green" } = props;
  return (
    <GenericCard>
      <Grid container spacing={1}>
        <Grid
          xs={12}
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <Chip label={title} style={{ backgroundColor: color }} />
        </Grid>
        <Grid xs={12}>
          <Typography variant={"h4"} align={"center"}>
            {dataValue}
            <Typography
              variant={"body2"}
              style={{ textTransform: "uppercase" }}
            >
              {timeframe}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </GenericCard>
  );
};
