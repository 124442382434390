import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@mui/material";

/**
 * Page
 * Is a template that all pages should use,
 * it provides a wrapper that shows the menu
 * header and footer.
 * It is built using the Material UI Grid
 * @param props
 * @constructor
 */
const Page = (props: { children: React.ReactNode }) => {
  const { children } = props;

  return (
    <Container
      maxWidth={false}
      style={{ padding: 30, background: "#192142", minHeight: "100vh" }}
    >
      {children}
    </Container>
  );
};

export default Page;
