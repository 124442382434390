import settings from "../settings";

type CarbonDataResponse = {
    carbon_emitted_consumption_grams_per_kwh: Array<{
        _time: string,
        value: number
    }>,
    cum_sum_carbon_emitted_sec_sec: Array<{
        _time: string,
        value: number
    }>,
    cum_sum_carbon_emitted_hourly: Array<{
        _time: string,
        value: number
  }>,
    carbon_avoided: Array<{
        _time: string,
        value: number
  }>,
    carbon_emitted: Array<{
        _time: string,
        value: number
  }>

}


export const get_carbon_data = async (props:{ start_date: Date, end_date: Date }): Promise<CarbonDataResponse> => {
  const { start_date, end_date } = props;
  const url = `${
    settings.api_url
  }dashboard/carbon?start_time=${start_date.toISOString()}&end_time=${end_date.toISOString()}`;
  const response = await fetch(url);
  return await response.json();
};
