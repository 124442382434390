import React from "react";
import * as d3 from "d3";
import { DataPoint } from "../types/DataPoint";
import { Typography } from "@mui/material";

export const AreaGraph = (props: {
  height: number;
  data: DataPoint[];
  y_axis_label?: string;
}) => {
  const svg_ref = React.useRef<SVGSVGElement>(null);
  const [parent_width, set_parent_width] = React.useState<number>(0);

  const { height, data, y_axis_label } = props;

  React.useEffect(() => {
    //     Listen for resize events
    const resizeListener = () => {
      set_parent_width(svg_ref.current?.parentElement?.clientWidth || 0);
    };
    window.addEventListener("resize", resizeListener);

    // Call resize listener once to set the initial width
    resizeListener();

    return () => {
      // cleanup
      window.removeEventListener("resize", resizeListener);
    };
  }, [svg_ref]);

  React.useEffect(() => {
    if (!svg_ref.current || !data.length) {
      return;
    } else {
      //     Set the svg width and height
      const svgSelect = d3.select(svg_ref.current);

      const svg = svgSelect.append("g");

      svgSelect.attr("width", parent_width + 50);
      svgSelect.attr("height", height + 50);

      // Assume data is in order of time
      const start = data[0].x;
      const now = data[data.length - 1].x;

      // Get all the x values
      const x_values = data.map((d) => d.x);
      // Get all the y values
      const y_values = data.map((d) => d.y);

      const x = d3.scaleLinear().domain([start, now]).range([0, parent_width]);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.y) || 0])
        .range([0, height]);
      // Show area under line
      const area = d3
        .area<number>()
        .x((d, i) => x(x_values[i] || 0))
        .y0(height)
        .y1((d) => height - y(d));

      // Show y axis
      const y_axis = d3.axisLeft(y).ticks(5);

      // Draw the y axis on the svg and rotate the text
      const y_axis_group = svg.append("g");
      y_axis_group
        .append("g")
        .attr("transform", `translate(0,0)`)
        .call(
          y_axis,
          // .tickSize(parent_width)
          // .tickFormat((d) => `${d} gCO2/kWh`)
        )
        .attr("transform", `rotate(180),translate(0,${-height})`)
        .selectAll("text")
        .attr("transform", "rotate(180)");

      y_axis_group
        .selectAll("line")
        .attr("stroke", "#5C84AA")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "5,5")
        .attr("stroke-opacity", 0.5)
        .attr("stroke-linecap", "round")
        // Add width to the line
        .attr("x2", parent_width)
        .attr("transform", `rotate(180),translate(0,0)`);

      svg
        .append("path")
        .attr("d", area(y_values) || "")
        .attr("fill", "url(#gradient)")
        .attr("stroke", "url(#strokeGradient)");

      svg
        .append("linearGradient")
        .attr("id", "gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", height)
        .attr("y2", 0)
        .selectAll("stop")
        .data([
          { offset: "0%", color: "#145593" },
          { offset: "45%", color: "#2491EB" },
          { offset: "100%", color: "#43EFFF" },
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);

      // Draw the x axis with the date
      svg
        .append("g")
        .attr("transform", `translate(0,${height - 1})`)
        //   Tick format is the date
        .call(
          d3
            .axisBottom(x)
            .tickFormat(d3.timeFormat("%d-%m-%y %H:%M:%S") as any),
        );

      // Add y-axis label
      svg
        .append("text")
        .attr("transform", `translate(-35,${height / 2})rotate(270)`)
        .text(y_axis_label ? y_axis_label : "Carbon Intensity (gCO2/kWh)")
        .attr("fill", "#5C84AA")
        .attr("text-anchor", "middle");

      const textGroup = svg.append("g");

      //     On hover show the value
      textGroup
        .append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", (d, i) => x(d.x))
        .attr("cy", (d) => height - y(d.y))
        .attr("r", 1)
        .attr("fill", "#43EFFF")
        .attr("stroke", "#43EFFF")
        .attr("stroke-width", 1)
        .attr("opacity", 1)
        .on("mouseover", function (d) {
          d3.select(this).attr("r", 5);
        })
        .on("mouseout", function () {
          d3.select(this).attr("r", 1);
          //     Remove the tooltip
        });

      //     Apply padding to the svg
      svg.attr("transform", `translate(50,25)`);
    }

    return () => {
      // cleanup
      if (svg_ref.current) {
        d3.select(svg_ref.current).selectAll("*").remove();
      }
    };
  }, [svg_ref, parent_width, data]);

  return (
    <>
      <svg ref={svg_ref} />
      {data.length === 0 && (
        <Typography variant={"h4"} align={"center"}>
          Loading...
        </Typography>
      )}
    </>
  );
};
