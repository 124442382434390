import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material";
import Dashboard from "./pages/Dashboard/Dashboard";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <>
    <CssBaseline />
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "dark",
        },
        typography: {
          allVariants: {
            color: "#fff",
            fontFamily: "Syne",
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: "1.5rem",
              },
            },
          },
        },
      })}
    >
      <Dashboard />
    </ThemeProvider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
