import React from "react";
import * as d3 from "d3";
import { geoMercator, geoPath } from "d3-geo";
import * as loadMap from "./map.json";
import { Typography } from "@mui/material";
import { FaSolarPanel, FaHome } from "react-icons/fa";
import { IconType } from "react-icons";

const map = loadMap as any;

type Location = {
  lat: number;
  lng: number;
};

type LocationData = {
  location: Location;
  icon: IconType;
  name: string;
  color: string;
};

type Connection = {
  from: Location;
  to: Location;
};

export const NetworkGraph = (props: {
  svg_height?: number;
  svg_width?: number;
  locations?: LocationData[];
  connections?: Connection[];
  example?: boolean;
}) => {
  const { svg_height = 400, svg_width = 400, example } = props;
  let { locations, connections } = props;

  if (!locations?.length && !connections?.length && example) {
    //   Load example data
    locations = [
      {
        location: { lat: 52.192705, lng: -2.029366 },
        icon: FaSolarPanel,
        name: "Worcester",
        color: "#00ffb2",
      },
      {
        location: { lat: 51.507222, lng: -0.12755 },
        icon: FaHome,
        name: "London",
        color: "#FF00FF",
      },
    ];

    connections = [
      {
        from: locations[0].location,
        to: locations[1].location,
      },
    ];
  }

  const svg_ref = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    const draw_map = async () => {
      if (svg_ref.current) {
        // Set size of the svg
        svg_ref.current.setAttribute("width", String(svg_width));
        svg_ref.current.setAttribute("height", String(svg_height));

        const svg = d3.select(svg_ref.current);

        //   Zoom in on the map
        const handle_zoom = (event: any) => {
          const transform = event.transform;
          svg.selectAll(".map").attr("transform", transform);
          svg.selectAll(".icon").attr("transform", transform);
          svg.selectAll(".connection").attr("transform", transform);
        };

        const zoom = d3.zoom().on("zoom", handle_zoom);
        svg.call(zoom as any);

        // Set the svg background color
        svg.style("background-color", "#2c446a");
        // Set the corners to rounded
        svg.style("border-radius", "20px");

        const projection = geoMercator().fitSize(
          [svg_width, svg_height],
          map as any,
        );
        //   draw the map
        const path = geoPath(projection);

        svg
          .append("g")
          .attr("class", "map")
          .selectAll("path")
          .data(map.features)
          .enter()
          .append("path")
          .attr("d", path as any)
          .style("fill", "rgba(255, 255, 255, 0)")
          .style("stroke-width", 1)
          .style("stroke", "#0dc3ef")
          .style("opacity", 1);

        const draw_connections = (
          connections: Connection[],
          d3Selection: d3.Selection<any, any, any, any>,
        ) => {
          connections.forEach((connection) => {
            d3Selection
              .append("path")
              .attr("class", "connection")
              .attr(
                "d",
                path({
                  type: "LineString",
                  coordinates: [
                    [connection.from.lng, connection.from.lat],
                    [connection.to.lng, connection.to.lat],
                  ],
                }),
              )
              .attr("fill", "none")
              .attr("stroke", "#00ffb2")
              .attr("stroke-width", 1);
          });
        };

        const draw_point = (
          location: LocationData,
          d3Selection: d3.Selection<any, any, any, any>,
          icon: IconType,
        ) => {
          //   This is the icon in pure Path data
          const iconInstructions = icon({}).props.children[0].props.d;

          const d3Icon = d3Selection
            .append("g")
            .attr("class", "icon")
            .on("mouseover", function () {
              d3.select(this).attr("r", 10);
              //     Add a tooltip
              svg
                .append("text")
                .attr(
                  "x",
                  projection([
                    location.location.lng,
                    location.location.lat,
                  ])![0] + 10,
                )
                .attr(
                  "y",
                  projection([
                    location.location.lng,
                    location.location.lat,
                  ])![1] + 10,
                )
                .text(location.name);
            })
            .on("mouseout", function () {
              d3.select(this).attr("r", 5);
              //     Remove the tooltip
              svg.selectAll("text").remove();
            });

          // Add a circle to the location
          d3Icon
            .append("circle")
            .attr(
              "cx",
              projection([location.location.lng, location.location.lat])![0],
            )
            .attr(
              "cy",
              projection([location.location.lng, location.location.lat])![1],
            )
            .attr("r", 20)
            // Take location color and add transparency
            .attr("fill", location.color + "20");

          d3Icon
            .append("g")
            // Viewbox is the size of the icon
            .append("path")
            .attr("d", iconInstructions)
            // Define the size of the icon

            // Center the icon on the location by taking the svg coordinates and subtracting half the width and height
            .attr(
              "transform",
              `translate(${
                projection([location.location.lng, location.location.lat])![0] -
                15
              }, ${
                projection([location.location.lng, location.location.lat])![1] -
                12
              }) , scale(0.05)`,
            )
            .attr("fill", location.color)
            .attr("stroke", location.color)
            .attr("stroke-width", 1)
            .attr("opacity", 1);
        };

        draw_connections(connections || [], svg);
        locations?.forEach((location) => {
          draw_point(location, svg, location.icon);
        });
      }
    };
    draw_map();

    return () => {
      // Clean up
      if (svg_ref.current) {
        d3.select(svg_ref.current).selectAll("*").remove();
      }
    };
  }, [svg_ref.current]);

  return (
    <>
      <svg ref={svg_ref} />
    </>
  );
};

export const Legend = (props: { children: React.ReactNode[] }) => {
  const { children } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {children}
    </div>
  );
};

export const LegendItem = (props: {
  dotColor: string;
  type: string;
  location: string;
}) => {
  const { dotColor, type, location } = props;

  return (
    <div
      style={{ display: "flex", flexDirection: "row", width: "100%", gap: 5 }}
    >
      <span style={{ color: dotColor, flexShrink: 1 }}>&#9679;</span>
      <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
        <Typography textTransform={"uppercase"} color={"#5C84AA"}>
          {type}
        </Typography>
        <Typography color={"#FFFFFF"}>{location}</Typography>
      </div>
    </div>
  );
};
