import React, { ReactNode, useEffect } from "react";
import Page from "../page";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Chip, MenuItem, Select, Typography } from "@mui/material";
import { DataCard } from "../../components/DataCard";
import {
  Legend,
  LegendItem,
  NetworkGraph,
} from "../../components/NetworkGraph/NetworkGraph";
import { Scrubber } from "../../components/Scrubber";
import settings from "../../settings";
import { GenericCard } from "../../components/GenericCard";
import { CGauge } from "../../components/CGauge";
import { AreaGraph } from "../../components/AreaGraph";
import { DataPoint } from "../../types/DataPoint";
import { LineGraph } from "../../components/LineGraph";
import Logo from "../../components/Logo";
import { get_carbon_data } from "../../api/api";

const convert_measurement = (measurement: any) => {
  return measurement?.map((d: { _time: string; value: number }) => ({
    x: new Date(d._time),
    y: d.value,
  }));
};

const Dashboard = () => {
  type DashboardData = {
    avg_carbon_emitted: number;
    avg_carbon_avoided: number;
    avg_carbon_intensity: number;
    carbon_intensity: DataPoint[];
    carbon_avoided: DataPoint[];
    carbon_emitted: DataPoint[];
    carbon_speedometer: number;
    first_timestamp: Date;
    last_timestamp: Date;
    selected_range: Date;
    cum_sum_carbon_emitted_sec_sec: DataPoint[];
    cum_sum_carbon_emitted_hourly: DataPoint[];
  };

  type HistoricalData = {
    cum_sum_carbon_emitted_sec_sec: DataPoint[];
    cum_sum_carbon_emitted_hourly: DataPoint[];
    carbon_intensity: DataPoint[];
    carbon_avoided: DataPoint[];
    carbon_emitted: DataPoint[];
  };

  type ScrubData = {
    start_date: Date | null;
    end_date: Date | null;
  };

  enum GraphSelection {
    CARBON_INTENSITY = "Carbon Intensity",
    CARBON_EMITTED = "Carbon Emitted",
    CARBON_AVOIDED = "Carbon Avoided",
  }

  const now = new Date();
  const [data, setData] = React.useState<DashboardData>({
    avg_carbon_emitted: 0,
    avg_carbon_avoided: 0,
    avg_carbon_intensity: 0,
    carbon_intensity: [],
    carbon_avoided: [],
    carbon_emitted: [],
    carbon_speedometer: 0,
    cum_sum_carbon_emitted_sec_sec: [],
    cum_sum_carbon_emitted_hourly: [],
    first_timestamp: now,
    last_timestamp: now,
    selected_range: now,
  });

  const [currentTime, setCurrentTime] = React.useState<Date>(new Date());

  const [scrubData, setScrubData] = React.useState<ScrubData>({
    start_date: null,
    end_date: null,
  });

  const [live, setLive] = React.useState<boolean>(true);
  const [historicalData, setHistoricalData] = React.useState<HistoricalData>({
    cum_sum_carbon_emitted_sec_sec: [],
    cum_sum_carbon_emitted_hourly: [],
    carbon_intensity: [],
    carbon_avoided: [],
    carbon_emitted: [],
  });

  const [graphSelection, setGraphSelection] = React.useState<GraphSelection>(
    GraphSelection.CARBON_INTENSITY,
  );

  const clientRef = React.useRef<any>(null);
  const [showSpeedometer, setShowSpeedometer] = React.useState<boolean>(true);
  const [waitingToReconnect, setWaitingToReconnect] =
    React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    const carbon = async (props: { start_date: Date; end_date: Date }) => {
      const { start_date, end_date } = props;
      // Now and 1 hour ago
      const data = await get_carbon_data({
        start_date: start_date,
        end_date: end_date,
      });
      const carbon_intensity = convert_measurement(
        data?.carbon_emitted_consumption_grams_per_kwh,
      );
      const cum_sum_carbon_emitted_sec_sec = convert_measurement(
        data?.cum_sum_carbon_emitted_sec_sec,
      );
      const cum_sum_carbon_emitted_hourly = convert_measurement(
        data?.cum_sum_carbon_emitted_hourly,
      );

      const carbon_avoided = convert_measurement(data?.carbon_avoided);

      const carbon_emitted = convert_measurement(data?.carbon_emitted);

      setHistoricalData({
        ...historicalData,
        carbon_intensity: carbon_intensity || [],
        cum_sum_carbon_emitted_sec_sec: cum_sum_carbon_emitted_sec_sec || [],
        cum_sum_carbon_emitted_hourly: cum_sum_carbon_emitted_hourly || [],
        carbon_avoided: carbon_avoided || [],
        carbon_emitted: carbon_emitted || [],
      });
    };

    // If we are not live and we have scrub data
    if (!live && scrubData.start_date && scrubData.end_date) {
      carbon({
        start_date: scrubData.start_date,
        end_date: scrubData.end_date,
      });
    }

    //   Fetch data from now until 1 hour ago
  }, [live, scrubData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    // Only set up the websocket once
    if (!clientRef.current) {
      const client = new WebSocket(settings.ws_url);
      clientRef.current = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        setIsOpen(true);
        console.log("ws opened");
        client.send("ping");
      };

      client.onclose = () => {
        if (clientRef.current) {
          // Connection failed
          console.log("ws closed by server");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log("ws closed by app component unmount");
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // Parse event code and log
        setIsOpen(false);
        console.log("ws closed");

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(false), 5000);
      };

      client.onmessage = (event) => {
        const message = JSON.parse(event.data);

        console.warn(message);

        // Load carbon_emitted_consumption_grams_per_kwh_measurement which {_time: '2023-09-27 15:59:05+00:00', value: 6.2045743790910155} to DataPoint
        const carbon_intensity = convert_measurement(
          message?.carbon_emitted_consumption_grams_per_kwh_measurement,
        );
        const carbon_avoided = convert_measurement(message?.carbon_avoided);

        const carbon_emitted = convert_measurement(message?.carbon_emitted);

        const cum_sum_carbon_emitted_sec_sec = convert_measurement(
          message?.cum_sum_carbon_emitted_measurement_sec_sec,
        );
        const cum_sum_carbon_emitted_hourly = convert_measurement(
          message?.cum_sum_carbon_emitted_measurement_hourly,
        );

        // Update the data
        setData({
          ...data,
          carbon_speedometer: message?.carbon_emitted_speedometer?.value,
          carbon_intensity: carbon_intensity || [],
          carbon_avoided: carbon_avoided || [],
          carbon_emitted: carbon_emitted || [],
          avg_carbon_emitted: message?.carbon_emitted_last_1h / 1000,
          avg_carbon_avoided: message?.carbon_avoided_lifetime / 1000,
          avg_carbon_intensity: message?.avg_carbon_intensity,
          cum_sum_carbon_emitted_sec_sec: cum_sum_carbon_emitted_sec_sec || [],
          cum_sum_carbon_emitted_hourly: cum_sum_carbon_emitted_hourly || [],
          first_timestamp: new Date(message?.first_timestamp),
          last_timestamp: new Date(message?.last_timestamp),
          selected_range: new Date(message?.selected_range),
        });
      };

      return () => {
        console.log("Cleanup");
        // Dereference, so it will set up next time
        clientRef.current = null;

        client.close();
      };
    }
  }, [waitingToReconnect]);

  useEffect(() => {
    if (
      (!scrubData.start_date &&
        !scrubData.end_date &&
        data.first_timestamp != data.last_timestamp) ||
      live
    ) {
      // Set the scrub data to the last hour
      setScrubData({
        start_date: data.selected_range,
        end_date: data.last_timestamp,
      });
      console.log("Setting scrub data");
    }
  }, [data]);

  const loading = (children: any) => {
    if (!isOpen || data.carbon_intensity.length === 0) {
      return "Loading...";
    }
    return children;
  };

  const handleGraphSelectionChange = (event: any) => {
    console.log(event);
    setGraphSelection(event.target.value as GraphSelection);
  };

  const renderAreaGraph = () => {
    let props;
    switch (graphSelection) {
      case GraphSelection.CARBON_INTENSITY:
        props = {
          height: 300,
          data: live ? data.carbon_intensity : historicalData.carbon_intensity,
        };
        break;
      case GraphSelection.CARBON_EMITTED:
        props = {
          height: 300,
          data: live ? data.carbon_emitted : historicalData.carbon_emitted,
          y_axis_label: "Carbon Emitted (g)",
        };
        break;
      case GraphSelection.CARBON_AVOIDED:
        props = {
          height: 300,
          data: live ? data.carbon_avoided : historicalData.carbon_avoided,
          y_axis_label: "Carbon Avoided (g)",
        };
        break;
      default:
        props = { height: 300, data: data.carbon_intensity };
        break;
    }
    return <AreaGraph {...props} />;
  };

  return (
    <Page>
      <Grid spacing={2} container>
        <Grid spacing={2} md={6} xs={12} container>
          <Grid xs={12}>
            <div
              style={{ display: "flex", justifyContent: "flex-start", gap: 20 }}
            >
              {/*Add Logo*/}
              <Logo height={85} width={85} />
              <div>
                <Typography variant={"subtitle2"} textTransform={"uppercase"}>
                  Home • Dashboard
                </Typography>
                <Typography variant={"h2"}>Live Carbon Tracking</Typography>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <Scrubber
              onLiveChange={(live) => {
                setLive(live);
              }}
              onChange={(event, value, thumb) => {
                // Check if value is an array
                if (Array.isArray(value)) {
                  // sort value in order of largest to smallest
                  const sorted = value.sort((a: number, b: number) => b - a);
                  // Largest value is the end date
                  const end_date = new Date(sorted[0]);
                  // Smallest value is the start date
                  const start_date = new Date(sorted[1]);

                  // Update scrubData
                  setScrubData({
                    start_date: start_date,
                    end_date: end_date,
                  });
                }
              }}
              endDateTime={scrubData.end_date ? scrubData.end_date : new Date()}
              startDateTime={
                scrubData.start_date ? scrubData.start_date : new Date()
              }
              maxDateTime={data.last_timestamp}
              minDateTime={data.first_timestamp}
            />
          </Grid>
          <>
            <Grid xs={12} md={4}>
              <DataCard
                title={"Carbon Emitted"}
                dataValue={loading(`${data.avg_carbon_emitted?.toFixed(2)} Kg`)}
                timeframe={"Last 1h"}
                color={"#2491EB"}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <DataCard
                title={"Carbon Avoided"}
                dataValue={loading(`${data.avg_carbon_avoided?.toFixed(2)} Kg`)}
                timeframe={"Lifetime"}
                color={"#0DC3EF"}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <DataCard
                title={"Avg Carbon Intensity"}
                dataValue={loading(
                  `${data.avg_carbon_intensity?.toFixed(2)} g/KWh`,
                )}
                timeframe={"Last 1h"}
                color={"#37CF9D"}
              />
            </Grid>
          </>
          <Grid xs={12}>
            <GenericCard>
              <Typography
                variant={"body2"}
                style={{ textTransform: "uppercase" }}
              >
                Energy Map
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Legend>
                  <LegendItem
                    dotColor={"#00ffb2"}
                    type={"solar panels"}
                    location={"Worcester"}
                  />
                  <LegendItem
                    dotColor={"#FF00FF"}
                    type={"Home Office"}
                    location={"London"}
                  />
                </Legend>
                <div style={{ flex: 1, width: "100%" }}>
                  <NetworkGraph example />
                </div>
              </Box>
            </GenericCard>
          </Grid>
        </Grid>
        <Grid spacing={2} md={6} xs={12} container>
          <Grid xs={12}>
            <GenericCard>
              <Button
                variant={showSpeedometer ? "contained" : "outlined"}
                color="primary"
                onClick={() => setShowSpeedometer(true)}
              >
                Carbon Speedometer
              </Button>
              <Button
                variant={showSpeedometer ? "outlined" : "contained"}
                color="primary"
                onClick={() => setShowSpeedometer(false)}
              >
                CO2 Emissions
              </Button>

              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {showSpeedometer ? (
                  <>
                    <CGauge value={isOpen ? data.carbon_speedometer : 0} />
                    <Chip
                      label={loading(
                        //   Show current time utc - 30 seconds
                        `Live ${currentTime.toLocaleString()}`,
                      )}
                      variant={"outlined"}
                      style={{ backgroundColor: "red", fontSize: 20 }}
                    />
                  </>
                ) : live ? (
                  <>
                    <LineGraph
                      height={300}
                      data={[
                        {
                          name: "Sec Sec",
                          color: "#FFF",
                          data: data.cum_sum_carbon_emitted_sec_sec,
                        },
                        {
                          name: "Hourly",
                          data: data.cum_sum_carbon_emitted_hourly,
                        },
                      ]}
                    />
                    <Chip
                      label={loading(`Live ${currentTime.toLocaleString()}`)}
                      variant={"outlined"}
                      style={{ backgroundColor: "red", fontSize: 20 }}
                    />
                  </>
                ) : (
                  <>
                    <LineGraph
                      height={300}
                      data={[
                        {
                          name: "Sec Sec",
                          color: "#FFF",
                          data: historicalData.cum_sum_carbon_emitted_sec_sec,
                        },
                        {
                          name: "Hourly",
                          data: historicalData.cum_sum_carbon_emitted_hourly,
                        },
                      ]}
                    />
                  </>
                )}
              </div>
            </GenericCard>
          </Grid>
          <Grid xs={12}>
            <GenericCard>
              <Select
                value={graphSelection}
                onChange={handleGraphSelectionChange}
              >
                {Object.values(GraphSelection).map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                margin={3}
              >
                {renderAreaGraph()}
              </Box>
            </GenericCard>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;
