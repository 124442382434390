import React, { useEffect } from "react";
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { GenericCard } from "./GenericCard";
import { Simulate } from "react-dom/test-utils";
import change = Simulate.change;

export const Scrubber = (props: {
  onChange: (
    event: React.SyntheticEvent | Event,
    value: number | number[],
    thumb?: number,
  ) => void;
  startDateTime: Date;
  endDateTime: Date;
  maxDateTime: Date;
  minDateTime: Date;
  onLiveChange: (live: boolean) => void;
}) => {
  const [live, setLive] = React.useState<boolean>(true);

  useEffect(() => {
    props.onLiveChange(live);
  }, [live]);

  const { onChange, startDateTime, endDateTime, maxDateTime, minDateTime } =
    props;

  const valuetext = (value: number) => {
    return new Date(value).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  //  get the difference in hours between start and end
  const hours = Math.floor(
    (endDateTime.getTime() - startDateTime.getTime()) / 1000 / 60 / 60,
  );
  // do a for loop and generate marks
  const marks = [];
  for (let i = 0; i < Math.floor(hours / 24); i++) {
    const time = startDateTime.getTime() + i * 1000 * 60 * 60 * 24;
    marks.push({
      value: time,
      label: valuetext(time),
    });
  }

  return (
    <GenericCard>
      <Typography style={{ textTransform: "uppercase" }} variant={"body2"}>
        {" "}
        Time Period
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 20,
          marginTop: 20,
        }}
      >
        {/*  Ignore seconds and mins*/}
        <Chip
          label={startDateTime.toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
          variant={"outlined"}
        />
        <Slider
          valueLabelDisplay="off"
          value={[startDateTime.getTime(), endDateTime.getTime()]}
          max={maxDateTime.getTime()}
          min={minDateTime.getTime()}
          getAriaValueText={valuetext}
          onChangeCommitted={onChange}
          disableSwap
          marks={marks}
          disabled={live}
        />
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Chip label={"Now"} variant={"outlined"} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch defaultChecked onChange={() => setLive(!live)} />
              }
              label="Live"
            />
          </FormGroup>
        </div>
      </div>
    </GenericCard>
  );
};
