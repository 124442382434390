import React from "react";
import * as d3 from "d3";
import * as d3scale from "d3-scale";
import * as d3interpolate from "d3-interpolate";
export const CGauge = (props: {
  value: number;
  major?: number;
  minor?: number;
  max?: number;
  min?: number;
  padding?: number;
  bottomMargin?: number;
}) => {
  const {
    value,
    major = 100,
    minor = 10,
    min = 0,
    padding = 30,
    bottomMargin = 50,
  } = props;

  let { max = 400 } = props;

  max = Math.max(max, value);

  const svg_ref = React.useRef<SVGSVGElement>(null);

  const [parent_width, set_parent_width] = React.useState<number>(0);

  React.useEffect(() => {
    //     Listen for resize events
    const resizeListener = () => {
      set_parent_width(svg_ref.current?.parentElement?.clientWidth || 0);
    };
    window.addEventListener("resize", resizeListener);

    // Call resize listener once to set the initial width
    resizeListener();

    return () => {
      // cleanup
      window.removeEventListener("resize", resizeListener);
    };
  }, [svg_ref]);

  React.useEffect(() => {
    if (svg_ref.current) {
      const svg = d3.select(svg_ref.current);

      const gauge_selection = svg.append("g");

      // Set the svg width and height
      svg
        .attr("width", parent_width)
        .attr("height", parent_width / 2 + bottomMargin);

      const width = parent_width - padding * 2;
      const height = (parent_width - padding * 2) / 2;

      // Convert max/min to degrees
      const degree = d3scale.scaleLinear().domain([min, max]).range([-90, 90]);

      // value to color
      const colorInterp = (startColor: string, endColor: string) =>
        d3interpolate.interpolateRgb(startColor, endColor);
      const colorScale = (start: number, end: number) =>
        d3scale.scaleLinear().domain([start, end]).range([0, 1]);

      const colorGradient = (props: {
        value: number;
        start?: number;
        end?: number;
        startColor?: string;
        endColor?: string;
      }) => {
        const {
          value,
          start = min,
          end = max,
          startColor = "#145593",
          endColor = "#43EFFF",
        } = props;
        return colorInterp(startColor, endColor)(colorScale(start, end)(value));
      };

      // Draw a minor tick mark every minor
      const minor_ticks = d3.range(min, max + 1, minor);

      const minor_ticks_group = gauge_selection.append("g");

      minor_ticks_group
        .selectAll("line.minor")
        .data(minor_ticks)
        .enter()
        .append("line")
        .attr("class", "minor")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", -height + 10)
        .attr("y2", -height)
        .attr("transform", (d) => `rotate(${degree(d)})`)
        .style("stroke", (d) =>
          d <= value ? colorGradient({ value: d, end: value }) : "#fff",
        )
        // Transform to the middle of the svg
        .style("stroke-width", 3);

      // Center the minor ticks
      minor_ticks_group.attr(
        "transform",
        `translate(${width / 2 + padding},${height + padding})`,
      );

      // Draw a major tick mark every major
      const major_ticks = d3.range(min, max + 1, major);

      const major_ticks_group = gauge_selection.append("g");

      major_ticks_group
        .selectAll("line.major")
        .data(major_ticks)
        .enter()
        .append("line")
        .attr("class", "major")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", -height + 20)
        .attr("y2", -height)
        .attr("transform", (d) => `rotate(${degree(d)})`)
        .style("stroke", (d) =>
          d <= value ? colorGradient({ value: d, end: value }) : "#fff",
        )
        // Transform to the middle of the svg
        .style("stroke-width", 6);

      // Center the major ticks
      major_ticks_group.attr(
        "transform",
        `translate(${width / 2 + padding},${height + padding})`,
      );

      //

      // Draw a smaller arc for the gauge
      const arc = d3
        .arc()
        .innerRadius(height - 50)
        // Round the edges
        .cornerRadius(5)
        .outerRadius(height - 40)
        .startAngle(-90 * (Math.PI / 180))
        .endAngle(degree(value) * (Math.PI / 180));

      const fillBar = gauge_selection.append("g");
      //     Semi circle
      fillBar
        .append("path")
        .attr("d", arc as any)
        .attr(
          "transform",
          `translate(${width / 2 + padding},${height + padding})`,
        )

        //         Fill using a gradient passing the degree of the value
        .style("fill", colorGradient({ value }));

      //     Inner values of major ticks
      const major_ticks_group_text = gauge_selection.append("g");

      major_ticks_group_text
        .selectAll("text.major")
        .data(major_ticks)
        .enter()
        .append("text")
        .attr("class", "major-tick")
        // Fill if below or equal to value
        .style("fill", "#FFF")
        // Scale text size based on width
        .style("font-size", `${width / 40}px`)
        .style("text-anchor", "middle")
        .text((d) => d)
        .attr("y", -height + 80)
        .attr("transform", (d) => `rotate(${degree(d)})`)
        .style("stroke", "#FFF");

      major_ticks_group_text.attr(
        "transform",
        `translate(${width / 2 + padding},${height + padding})`,
      );

      // Add the margin
      gauge_selection.attr("transform", `translate(${0},${bottomMargin / 2})`);

      //     Display the current value
      const current_value_group = gauge_selection.append("g");
      const current_value_font_px = width / 10;
      current_value_group
        .append("text")
        .attr("class", "current-value")
        .style("fill", "#fff")
        // Scale text size based on width
        .style("font-size", `${current_value_font_px}px`)
        .style("text-anchor", "middle")
        .text(value?.toFixed(2))
        .attr(
          "transform",
          `translate(${width / 2 + padding},${
            height + padding - current_value_font_px / 2
          })`,
        );

      //     Add the units

      current_value_group
        .append("text")
        .attr("class", "current-value")
        .style("fill", "#fff")
        // Scale text size based on width
        .style("font-size", `${current_value_font_px / 3}px`)
        .style("text-anchor", "middle")
        .text("gCO2 /kWh")
        .attr(
          "transform",
          `translate(${width / 2 + padding},${
            height +
            padding -
            current_value_font_px / 2 +
            current_value_font_px / 3
          })`,
        );
    }

    return () => {
      // cleanup
      if (svg_ref.current) {
        d3.select(svg_ref.current).selectAll("*").remove();
      }
    };
  }, [
    svg_ref,
    parent_width,
    value,
    major,
    minor,
    min,
    max,
    padding,
    bottomMargin,
  ]);

  return <svg ref={svg_ref} />;
};
