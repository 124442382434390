import React from "react";
import * as d3 from "d3";
import { DataPoint, GraphData } from "../types/DataPoint";
import { Typography } from "@mui/material";

export const LineGraph = (props: { height: number; data: GraphData[] }) => {
  const svg_ref = React.useRef<SVGSVGElement>(null);
  const [parent_width, set_parent_width] = React.useState<number>(0);

  const { height, data } = props;

  React.useEffect(() => {
    //     Listen for resize events
    const resizeListener = () => {
      if (svg_ref.current?.parentElement?.clientWidth) {
        set_parent_width(svg_ref.current?.parentElement?.clientWidth - 50 || 0);
      } else {
        set_parent_width(svg_ref.current?.parentElement?.clientWidth || 0);
      }
    };
    window.addEventListener("resize", resizeListener);

    // Call resize listener once to set the initial width
    resizeListener();

    return () => {
      // cleanup
      window.removeEventListener("resize", resizeListener);
    };
  }, [svg_ref]);

  React.useEffect(() => {
    if (!svg_ref.current || !data.length) {
      return;
    } else {
      //     Set the svg width and height
      const svgSelect = d3.select(svg_ref.current);

      const svg = svgSelect.append("g");

      svgSelect.attr("width", parent_width);
      svgSelect.attr("height", height + 50);

      // Order data by time
      const x_timestamps = data
        .map((d) => d.data.map((d) => d.x))
        .flat()
        .sort((t1, t2) => t1.getTime() - t2.getTime());

      // Assume data is in order of time
      const start_timestamp = x_timestamps[0];
      const end_timestamp = x_timestamps[x_timestamps.length - 1];

      const value_max =
        d3.max(data.map((d) => d.data.map((d) => d.y)).flat()) || 0;

      const x = d3
        .scaleLinear()
        .domain([start_timestamp, end_timestamp])
        .range([0, parent_width]);

      const y = d3
        .scaleLinear()
        .domain([0, value_max + value_max * 0.2])
        .range([0, height]);

      // Show y axis
      const y_axis = d3.axisLeft(y).ticks(5);

      // Draw the line for each GraphData
      data.forEach((graph_data) => {
        const line = d3
          .line<DataPoint>()
          .x((d, i) => x(d.x))
          .y((d) => height - y(d.y));

        svg
          .append("path")
          .datum(graph_data.data)
          .attr("fill", "none")
          .attr("stroke", graph_data.color || "red")
          .attr("stroke-opacity", 0.9)
          .attr("stroke-width", 3)
          .attr("d", line);

        // Light shade the area under the line
        const area = d3
          .area<DataPoint>()
          .x((d, i) => x(d.x))
          .y0(height)
          .y1((d) => height - y(d.y));

        svg
          .append("path")
          .datum(graph_data.data)
          .attr("fill", graph_data.color || "red")
          .attr("fill-opacity", 0.05)
          .attr("d", area);
      });

      const y_axis_ticks = svg.append("g");

      // Draw the y axis on the svg and rotate the text
      y_axis_ticks
        .append("g")
        .attr("transform", `translate(0,0)`)
        .call(y_axis)
        .attr("transform", `rotate(180),translate(0,${-height})`)
        .selectAll("text")
        .attr("transform", "rotate(180)");
      y_axis_ticks
        .selectAll("line")
        .attr("stroke", "#5C84AA")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "5,5")
        .attr("stroke-opacity", 0.5)
        .attr("stroke-linecap", "round")
        // Add width to the line
        .attr("x2", parent_width)
        .attr("transform", `rotate(180),translate(0,0)`);

      // Draw the x axis with the date
      svg
        .append("g")
        .attr("transform", `translate(0,${height})`)
        //   Tick format is the date
        .call(
          d3
            .axisBottom(x)
            .tickFormat(d3.timeFormat("%d-%m-%y %H:%M:%S") as any),
        );

      // Add y-axis label
      svg
        .append("text")
        .attr("transform", `translate(-50,${height / 2})rotate(270)`)
        .text("P90 Carbon Footprint (gCO2)")
        .attr("fill", "#5C84AA")
        .attr("text-anchor", "middle");

      // Add a legend using circles and text align to the end timestamp
      const legend = svg
        .append("g")
        .attr("transform", `translate(${parent_width - 150},${height * 0.1} )`);

      data.forEach((graph_data, i) => {
        legend
          .append("circle")
          .attr("cx", 0)
          .attr("cy", i * 30)
          .attr("r", 6)
          .attr("fill", graph_data.color || "red");
        legend
          .append("text")
          .attr("x", 10)
          .attr("y", i * 30)
          .text(graph_data.name)
          .attr("text-anchor", "start")
          .attr("fill", "#5C84AA")
          .style("alignment-baseline", "middle");
      });

      //     Apply padding to the svg
      svg.attr("transform", `translate(70,25)`);
    }

    return () => {
      // cleanup
      if (svg_ref.current) {
        d3.select(svg_ref.current).selectAll("*").remove();
      }
    };
  }, [svg_ref, parent_width, data]);

  return (
    <>
      <svg ref={svg_ref} style={{ margin: 50 }} />
      {data.length === 0 && (
        <Typography variant={"h4"} align={"center"}>
          Loading...
        </Typography>
      )}
    </>
  );
};
