import { Card, CardContent, Chip, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

export const GenericCard = (props: {
  children: React.ReactNode;
  cardContentProps?: React.ComponentProps<typeof CardContent>;
}) => {
  const { children, cardContentProps } = props;
  return (
    <Card style={{ background: "#213658" }}>
      <CardContent {...cardContentProps}>{children}</CardContent>
    </Card>
  );
};
